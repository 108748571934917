import React from 'react';
import '../Assets/css/footer.css';
import logo from '../Assets/img/segej logo.webp';
// import facebook_icon from '../Assets/icons/facebook.webp';
// import instagram_icon from '../Assets/icons/instagram.webp';
// import pintrest_icon from '../Assets/icons/pintrest.webp';
// import vimeo_icon from '../Assets/icons/vimeo.webp';
// import youtube_icon from '../Assets/icons/youtube.webp';
import linkedin_icon from '../Assets/icons/linkedin.png';
import phone_icon from '../Assets/icons/phone.webp';
import mail_icon from '../Assets/icons/mail.webp';
import { Link, useNavigate } from 'react-router-dom';

const Footer = ({ handleLogout }) => {
  const navigate = useNavigate();

  const social_data = [
    // { name: 'Facebook', img: facebook_icon },
    // { name: 'Instagram', img: instagram_icon },
    // { name: 'Pintrest', img: pintrest_icon },
    // { name: 'Vimeo', img: vimeo_icon },
    // { name: 'Youtube', img: youtube_icon }
    { name: 'Linked in', img: linkedin_icon , link: 'https://www.linkedin.com/in/sehej-kaur-611b8a240?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app'}
  ];

  const handleButtonClick = () => {
    if (handleLogout) {
      handleLogout();
      navigate('/'); // Navigate to home or a specific page after logout
    } else {
      navigate('/login');
    }
  };

  return (
    <>
      <div className="footer-container">
        <div className="main-footer">
          <img src={logo} alt="sehej" className="footer-logo" />

          <div className="footer-contact">
            <Link className="contact-item" to="https://www.linkedin.com/in/sehej-kaur-611b8a240?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app">
              <img src={linkedin_icon} alt="inkwed in" className="social-link-img" />
              <p className="contact-para">Linked in</p>
            </Link>
            <div className="contact-item">
              <img src={phone_icon} alt="contact" className="contact-icon" />
              <p className="contact-para">8744050851</p>
            </div>
            <Link className="contact-item" to="mailto:sehejk24@gmail.com">
              <img src={mail_icon} alt="contact" className="contact-icon" />
              <p className="contact-para">sehejk24@gmail.com</p>
            </Link>
          </div>
          <div className="login-button-container">
            <div className="login-button" onClick={handleButtonClick}>
              <p>{handleLogout ? 'Logout' : 'Login As Admin'}</p>
            </div>
          </div>
        </div>
        <div className="copyright-container">
          <p className="copyright-para">&copy; 2024 All rights reserved</p>
        </div>
      </div>
    </>
  );
};

export default Footer;

