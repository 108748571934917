import React, { useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import Spinner from "../Spinner";
const Addwork = () => {
  const navigate = useNavigate()
  const param = useParams();
  const [albumtitle, setalbumtitle] = useState("");
  const [albumtype, setalbumtype] = useState("");
  const [shortdesc, setshortdesc] = useState("");
  const [maindesc, setmaindesc] = useState("");
  const [image, setImage] = useState(null);
  const [img1, setimg1] = useState(null);
  const [img2, setimg2] = useState(null);
  const [img3, setimg3] = useState(null);
  const [img4, setimg4] = useState(null);
  const [img5, setimg5] = useState(null);
  const [img6, setimg6] = useState(null);
  const [img7, setimg7] = useState(null);
  const [img8, setimg8] = useState(null);
  const [img9, setimg9] = useState(null);
  const [img10, setimg10] = useState(null);
  const [check1, setcheck1] = useState(false);
  const [check2, setcheck2] = useState(false);
  const [check3, setcheck3] = useState(false);
  const [check4, setcheck4] = useState(false);
  const [check5, setcheck5] = useState(false);
  const [check6, setcheck6] = useState(false);
  const [check7, setcheck7] = useState(false);
  const [check8, setcheck8] = useState(false);
  const [check9, setcheck9] = useState(false);
  const [check10, setcheck10] = useState(false);
  const [existingImage, setExistingImage] = useState(null); // For the cover image
  const [existingImg1, setExistingImg1] = useState(null); // For the additional image 1
  const [existingImg2, setExistingImg2] = useState(null); // For the additional image 2
  const [existingImg3, setExistingImg3] = useState(null); // For the additional image 2
  const [existingImg4, setExistingImg4] = useState(null); // For the additional image 2
  const [existingImg5, setExistingImg5] = useState(null); // For the additional image 2
  const [existingImg6, setExistingImg6] = useState(null); // For the additional image 2
  const [existingImg7, setExistingImg7] = useState(null); // For the additional image 2
  const [existingImg8, setExistingImg8] = useState(null); // For the additional image 2
  const [existingImg9, setExistingImg9] = useState(null); // For the additional image 2
  const [existingImg10, setExistingImg10] = useState(null); // For the additional image 2
  const [existingImagePatharr, setExistingImagePatharr] = useState(null);
  const [existingImg1Patharr, setExistingImg1Patharr] = useState(null);
  const [existingImg2Patharr, setExistingImg2Patharr] = useState(null);
  const [existingImg3Patharr, setExistingImg3Patharr] = useState(null);
  const [existingImg4Patharr, setExistingImg4Patharr] = useState(null);
  const [existingImg5Patharr, setExistingImg5Patharr] = useState(null);
  const [existingImg6Patharr, setExistingImg6Patharr] = useState(null);
  const [existingImg7Patharr, setExistingImg7Patharr] = useState(null);
  const [existingImg8Patharr, setExistingImg8Patharr] = useState(null);
  const [existingImg9Patharr, setExistingImg9Patharr] = useState(null);
  const [existingImg10Patharr, setExistingImg10Patharr] = useState(null);
  const fileInputRef = useRef(null);
  const [loader, setLoader] = useState(false);

  const generateUniqueId = () => {
    return Math.floor(10000 + Math.random() * 90000); // Generates a 5-digit random number
  };

  useEffect(() => {
    if (param.id) {
      // Fetch the data if param.id is present (editing mode)
      const getData = async () => {
        try {
          const response = await fetch(
            "https://node-app.sehejkaur.com/getworks"
          );
          const data = await response.json();

          // Filter the array to find the object with the matching id
          const work = data.find((item) => item.id === parseInt(param.id));

          if (work) {
            // Fill the form with the filtered work data
            setalbumtitle(work.title);
            setalbumtype(work.type);
            setshortdesc(work.desc1);
            setmaindesc(work.desc2);
            setcheck1(!!work.img1);
            setcheck2(!!work.img2);
            setcheck3(!!work.img3);
            setcheck4(!!work.img4);
            setcheck5(!!work.img5);
            setcheck6(!!work.img6);
            setcheck7(!!work.img7);
            setcheck8(!!work.img8);
            setcheck9(!!work.img9);
            setcheck10(!!work.img10);

            // Set existing image URLs to display as previews
            setExistingImage(work.mainimg);
            setExistingImg1(work.img1);
            setExistingImg2(work.img2);
            setExistingImg3(work.img3);
            setExistingImg4(work.img4);
            setExistingImg5(work.img5);
            setExistingImg6(work.img6);
            setExistingImg7(work.img7);
            setExistingImg8(work.img8);
            setExistingImg9(work.img9);
            setExistingImg10(work.img10);
            if (work.mainimg) {
              setExistingImagePatharr(work.mainimg.split("/"));
            }
            if (work.img1) {
              setExistingImg1Patharr(work.img1.split("/"));
            }
            if (work.img2) {
              setExistingImg2Patharr(work.img2.split("/"));
            }
            if (work.img3) {
              setExistingImg3Patharr(work.img3.split("/"));
            }
            if (work.img4) {
              setExistingImg4Patharr(work.img4.split("/"));
            }
            if (work.img5) {
              setExistingImg5Patharr(work.img5.split("/"));
            }
            if (work.img6) {
              setExistingImg6Patharr(work.img6.split("/"));
            }
            if (work.img7) {
              setExistingImg7Patharr(work.img7.split("/"));
            }
            if (work.img8) {
              setExistingImg8Patharr(work.img8.split("/"));
            }
            if (work.img9) {
              setExistingImg9Patharr(work.img9.split("/"));
            }
            if (work.img10) {
              setExistingImg10Patharr(work.img10.split("/"));
            }
          } else {
            console.error(`No work found with id: ${param.id}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      getData();
    }
  }, [param.id]);

  const handleSubmit = async (e) => {
    setLoader(true);
    e.preventDefault();
    // const randomId = generateUniqueId();
    const formData = new FormData();
    formData.append("title", albumtitle);
    formData.append("type", albumtype);
    formData.append("mainimg", image);
    formData.append("desc1", shortdesc);
    formData.append("desc2", maindesc);
    // formData.append("id", randomId);
    if (check1) formData.append("img1", img1);
    if (check2) formData.append("img2", img2);
    if (check3) formData.append("img3", img3);
    if (check4) formData.append("img4", img4);
    if (check5) formData.append("img5", img5);
    if (check6) formData.append("img6", img6);
    if (check7) formData.append("img7", img7);
    if (check8) formData.append("img8", img8);
    if (check9) formData.append("img9", img9);
    if (check10) formData.append("img10", img10);

    try {
      const response = await fetch("https://node-app.sehejkaur.com/addwork", {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      setLoader(false);

      console.log(data, "response");

      // Reset the form after submission
      setalbumtitle("");
      setalbumtype("");
      setshortdesc("");
      setmaindesc("");
      setImage(null);
      setimg1(null);
      setimg2(null);
      setimg3(null);
      setimg4(null);
      setimg5(null);
      setimg6(null);
      setimg7(null);
      setimg8(null);
      setimg9(null);
      setimg10(null);
      fileInputRef.current.value = null;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("title", albumtitle);
    formData.append("type", albumtype);

    // Handle the main image logic (upload new or use existing)
    if (image) {
      formData.append("mainimg", image);
    } else if (existingImagePatharr && existingImagePatharr.length >= 4) {
      // Append existing image path if no new image is selected
      formData.append(
        "mainimg",
        existingImagePatharr[3].concat("/", existingImagePatharr[4])
      );
    }

    // Handle additional images
    if (check1) {
      if (img1) {
        formData.append("img1", img1); // New image1
      } else if (existingImg1Patharr && existingImg1Patharr.length >= 4) {
        formData.append(
          "img1",
          existingImg1Patharr[3].concat("/", existingImg1Patharr[4])
        ); // Existing img1
      }
    }

    if (check2) {
      if (img2) {
        formData.append("img2", img2); // New image2
      } else if (existingImg2Patharr && existingImg2Patharr.length >= 4) {
        formData.append(
          "img2",
          existingImg2Patharr[3].concat("/", existingImg2Patharr[4])
        ); // Existing img2
      }
    }
    if (check3) {
      if (img3) {
        formData.append("img3", img3); // New image3
      } else if (existingImg3Patharr && existingImg3Patharr.length >= 4) {
        formData.append(
          "img3",
          existingImg3Patharr[3].concat("/", existingImg3Patharr[4])
        ); // Existing img3
      }
    }
    if (check4) {
      if (img4) {
        formData.append("img4", img4); // New image4
      } else if (existingImg4Patharr && existingImg4Patharr.length >= 4) {
        formData.append(
          "img4",
          existingImg4Patharr[3].concat("/", existingImg4Patharr[4])
        ); // Existing img4
      }
    }
    if (check5) {
      if (img5) {
        formData.append("img5", img5); // New image5
      } else if (existingImg5Patharr && existingImg5Patharr.length >= 4) {
        formData.append(
          "img5",
          existingImg5Patharr[3].concat("/", existingImg5Patharr[4])
        ); // Existing img5
      }
    }
    if (check6) {
      if (img6) {
        formData.append("img6", img6); // New image6
      } else if (existingImg6Patharr && existingImg6Patharr.length >= 4) {
        formData.append(
          "img6",
          existingImg6Patharr[3].concat("/", existingImg6Patharr[4])
        ); // Existing img6
      }
    }
    if (check7) {
      if (img7) {
        formData.append("img7", img7); // New image7
      } else if (existingImg7Patharr && existingImg7Patharr.length >= 4) {
        formData.append(
          "img7",
          existingImg7Patharr[3].concat("/", existingImg7Patharr[4])
        ); // Existing img7
      }
    }
    if (check8) {
      if (img8) {
        formData.append("img8", img8); // New image8
      } else if (existingImg8Patharr && existingImg8Patharr.length >= 4) {
        formData.append(
          "img8",
          existingImg8Patharr[3].concat("/", existingImg8Patharr[4])
        ); // Existing img8
      }
    }
    if (check9) {
      if (img9) {
        formData.append("img9", img9); // New image9
      } else if (existingImg9Patharr && existingImg9Patharr.length >= 4) {
        formData.append(
          "img9",
          existingImg9Patharr[3].concat("/", existingImg9Patharr[4])
        ); // Existing img9
      }
    }
    if (check10) {
      if (img10) {
        formData.append("img10", img10); // New image10
      } else if (existingImg10Patharr && existingImg10Patharr.length >= 4) {
        formData.append(
          "img10",
          existingImg10Patharr[3].concat("/", existingImg10Patharr[4])
        ); // Existing img10
      }
    }

    formData.append("desc1", shortdesc);
    formData.append("desc2", maindesc);

    try {
      const response = await fetch(
        `https://node-app.sehejkaur.com/updatework/${param.id}`,
        {
          method: "PUT",
          body: formData,
        }
      );
      const data = await response.json();
      console.log(data, "response");

      // Reset the form after submission
      setalbumtitle("");
      setalbumtype("");
      setshortdesc("");
      setmaindesc("");
      setImage(null);
      setimg1(null);
      setimg2(null);
      fileInputRef.current.value = null;
    } catch (error) {
      console.error("Error:", error);
    }
    navigate(`/`)
  };

  const handleImageChange = (e) => setImage(e.target.files[0]);
  const handleimg1Change = (e) => setimg1(e.target.files[0]);
  const handleimg2Change = (e) => setimg2(e.target.files[0]);
  const handleimg3Change = (e) => setimg3(e.target.files[0]);
  const handleimg4Change = (e) => setimg4(e.target.files[0]);
  const handleimg5Change = (e) => setimg5(e.target.files[0]);
  const handleimg6Change = (e) => setimg6(e.target.files[0]);
  const handleimg7Change = (e) => setimg7(e.target.files[0]);
  const handleimg8Change = (e) => setimg8(e.target.files[0]);
  const handleimg9Change = (e) => setimg9(e.target.files[0]);
  const handleimg10Change = (e) => setimg10(e.target.files[0]);
  const handlecheck1 = (e) => setcheck1(e.target.checked);
  const handlecheck2 = (e) => setcheck2(e.target.checked);
  const handlecheck3 = (e) => setcheck3(e.target.checked);
  const handlecheck4 = (e) => setcheck4(e.target.checked);
  const handlecheck5 = (e) => setcheck5(e.target.checked);
  const handlecheck6 = (e) => setcheck6(e.target.checked);
  const handlecheck7 = (e) => setcheck7(e.target.checked);
  const handlecheck8 = (e) => setcheck8(e.target.checked);
  const handlecheck9 = (e) => setcheck9(e.target.checked);
  const handlecheck10 = (e) => setcheck10(e.target.checked);

  return (
    <>
      {loader ? (
        <Spinner />
      ) : (
        <div className="form-container">
          <h1>{param.id ? "Edit Work" : "Add a new Work"}</h1>
          <form
            onSubmit={param.id ? handleUpdate : handleSubmit}
            className="admin-form"
          >
            <div className="form-feild">
              <label htmlFor="album">Work Title : </label> <br />
              <input
                type="text"
                placeholder="eg. Dubai"
                value={albumtitle}
                onChange={(e) => setalbumtitle(e.target.value)}
                required
              />
            </div>
            <div className="form-feild">
              <label htmlFor="album">Work Type : </label> <br />
              <input
                type="text"
                placeholder="eg. Dubai"
                value={albumtype}
                onChange={(e) => setalbumtype(e.target.value)}
                required
              />
            </div>
            <div className="form-feild">
              <label htmlFor="album">Short Description : </label> <br />
              <textarea
                cols={100}
                rows={4}
                placeholder="Short description"
                value={shortdesc}
                onChange={(e) => setshortdesc(e.target.value)}
                required
              />
            </div>
            <div className="form-feild">
              <label htmlFor="album">Main Description : </label> <br />
              <textarea
                cols={100}
                rows={20}
                placeholder="Main description"
                value={maindesc}
                onChange={(e) => setmaindesc(e.target.value)}
                required
              />
            </div>
            <div className="form-feild-file">
              <label htmlFor="mainimg">Upload the cover Image : </label> <br />
              {existingImage && (
                <div>
                  <img src={existingImage} alt="Current Cover" width="150" />
                  <p>Current Image</p>
                </div>
              )}
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                ref={fileInputRef}
                required={!param.id} // Image is required when adding a new work
              />
            </div>
            <div>
              <label htmlFor="mainimg">Upload the additional Images : </label>{" "}
              <br />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check1} onChange={handlecheck1} />
              {existingImg1 && (
                <div>
                  <img
                    src={existingImg1}
                    alt="Current Additional Pic 1"
                    width="150"
                  />
                  <p>Current Image 1</p>
                </div>
              )}
              <input
                disabled={!check1}
                type="file"
                accept="image/*"
                onChange={handleimg1Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check2} onChange={handlecheck2} />
              {existingImg2 && (
                <div>
                  <img
                    src={existingImg2}
                    alt="Current Additional Pic 2"
                    width="150"
                  />
                  <p>Current Image 2</p>
                </div>
              )}
              <input
                disabled={!check2}
                type="file"
                accept="image/*"
                onChange={handleimg2Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check3} onChange={handlecheck3} />
              {existingImg3 && (
                <div>
                  <img
                    src={existingImg3}
                    alt="Current Additional Pic 3"
                    width="150"
                  />
                  <p>Current Image 3</p>
                </div>
              )}
              <input
                disabled={!check3}
                type="file"
                accept="image/*"
                onChange={handleimg3Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check4} onChange={handlecheck4} />
              {existingImg4 && (
                <div>
                  <img
                    src={existingImg4}
                    alt="Current Additional Pic 4"
                    width="150"
                  />
                  <p>Current Image 4</p>
                </div>
              )}
              <input
                disabled={!check4}
                type="file"
                accept="image/*"
                onChange={handleimg4Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check5} onChange={handlecheck5} />
              {existingImg5 && (
                <div>
                  <img
                    src={existingImg5}
                    alt="Current Additional Pic 5"
                    width="150"
                  />
                  <p>Current Image 5</p>
                </div>
              )}
              <input
                disabled={!check5}
                type="file"
                accept="image/*"
                onChange={handleimg5Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check6} onChange={handlecheck6} />
              {existingImg6 && (
                <div>
                  <img
                    src={existingImg6}
                    alt="Current Additional Pic 6"
                    width="150"
                  />
                  <p>Current Image 6</p>
                </div>
              )}
              <input
                disabled={!check6}
                type="file"
                accept="image/*"
                onChange={handleimg6Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check7} onChange={handlecheck7} />
              {existingImg7 && (
                <div>
                  <img
                    src={existingImg7}
                    alt="Current Additional Pic 7"
                    width="150"
                  />
                  <p>Current Image 7</p>
                </div>
              )}
              <input
                disabled={!check7}
                type="file"
                accept="image/*"
                onChange={handleimg7Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check8} onChange={handlecheck8} />
              {existingImg8 && (
                <div>
                  <img
                    src={existingImg8}
                    alt="Current Additional Pic 8"
                    width="150"
                  />
                  <p>Current Image 8</p>
                </div>
              )}
              <input
                disabled={!check8}
                type="file"
                accept="image/*"
                onChange={handleimg8Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check9} onChange={handlecheck9} />
              {existingImg9 && (
                <div>
                  <img
                    src={existingImg9}
                    alt="Current Additional Pic 9"
                    width="150"
                  />
                  <p>Current Image 9</p>
                </div>
              )}
              <input
                disabled={!check9}
                type="file"
                accept="image/*"
                onChange={handleimg9Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild-file">
              <input type="checkbox" checked={check10} onChange={handlecheck10} />
              {existingImg10 && (
                <div>
                  <img
                    src={existingImg10}
                    alt="Current Additional Pic 10"
                    width="150"
                  />
                  <p>Current Image 10</p>
                </div>
              )}
              <input
                disabled={!check10}
                type="file"
                accept="image/*"
                onChange={handleimg10Change}
                ref={fileInputRef}
              />
            </div>
            <div className="form-feild">
              <button type="submit" className="submit-btn">
                {param.id ? "Update" : "Submit"}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Addwork;
